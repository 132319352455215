import { HotelSearchQuery_Category } from '$lib/gen/hotel/v1/query_pb'

export interface Category {
  title: string
  id: string
  type: HotelSearchQuery_Category
  iconUrl: string
  slug: string
}

export function getCategoryById(id: string): Category {
  return categories.find((s) => s.id === id)
}

export function findCategoryName(id: string): string {
  return categories.find((s) => s.id === id)?.title ?? 'Hotel'
}

export const categories: Category[] = [
  {
    title: 'Hotel',
    id: 'HOTEL',
    type: HotelSearchQuery_Category.HOTEL,
    iconUrl: 'hotel.svg',
    slug: 'hotel',
  },
  {
    title: 'Apartment',
    id: 'APARTMENT',
    type: HotelSearchQuery_Category.APARTMENT,
    iconUrl: 'apartment.svg',
    slug: 'apartment',
  },
  {
    title: 'Private Home',
    id: 'PRIVATE_VACATION_HOME',
    type: HotelSearchQuery_Category.PRIVATE_VACATION_HOME,
    iconUrl: 'private-vacation-home.svg',
    slug: 'homes',
  },
  {
    title: 'Hostel',
    id: 'HOSTEL_BACKPACKER_ACCOMMODATION',
    type: HotelSearchQuery_Category.HOSTEL_BACKPACKER_ACCOMMODATION,
    iconUrl: 'hostel.svg',
    slug: 'hostel',
  },
  {
    title: 'Guesthouse',
    id: 'GUESTHOUSE',
    type: HotelSearchQuery_Category.GUESTHOUSE,
    iconUrl: 'guesthouse.svg',
    slug: 'guesthouse',
  },
  {
    title: 'B&B',
    id: 'BED_BREAKFAST',
    type: HotelSearchQuery_Category.BED_BREAKFAST,
    iconUrl: 'bed-breakfast.svg',
    slug: 'bed-breakfast',
  },
  {
    title: 'Villa',
    id: 'VILLA',
    type: HotelSearchQuery_Category.VILLA,
    iconUrl: 'villa.svg',
    slug: 'villa',
  },
  {
    title: 'Aparthotel',
    id: 'APARTHOTEL',
    type: HotelSearchQuery_Category.APARTHOTEL,
    iconUrl: 'aparthotel.svg',
    slug: 'aparthotel',
  },
  {
    title: 'Motel',
    id: 'MOTEL',
    type: HotelSearchQuery_Category.MOTEL,
    iconUrl: 'motel.svg',
    slug: 'motel',
  },
  {
    title: 'Condo',
    id: 'CONDO',
    type: HotelSearchQuery_Category.CONDO,
    iconUrl: 'condo.svg',
    slug: 'condo',
  },
  {
    title: 'Resort',
    id: 'RESORT',
    type: HotelSearchQuery_Category.RESORT,
    iconUrl: 'resort.svg',
    slug: 'resort',
  },
  {
    title: 'Pension',
    id: 'PENSION',
    type: HotelSearchQuery_Category.PENSION,
    iconUrl: 'pension.svg',
    slug: 'pension',
  },
  {
    title: 'Cottage',
    id: 'COTTAGE',
    type: HotelSearchQuery_Category.COTTAGE,
    iconUrl: 'cottage.svg',
    slug: 'cottage',
  },
  {
    title: 'Treehouse',
    id: 'TREE_HOUSE',
    type: HotelSearchQuery_Category.TREE_HOUSE,
    iconUrl: 'cottage.svg',
    slug: 'cottage',
  },
  { title: 'Inn', id: 'INN', type: HotelSearchQuery_Category.INN, iconUrl: 'inn.svg', slug: 'inn' },
  {
    title: 'Lodge',
    id: 'LODGE',
    type: HotelSearchQuery_Category.LODGE,
    iconUrl: 'lodge.svg',
    slug: 'lodge',
  },
  {
    title: 'Country House',
    id: 'COUNTRY_HOUSE',
    type: HotelSearchQuery_Category.COUNTRY_HOUSE,
    iconUrl: 'country-house.svg',
    slug: 'country-house',
  },
  {
    title: 'Agritourism',
    id: 'AGRITOURISM_PROPERTY',
    type: HotelSearchQuery_Category.AGRITOURISM_PROPERTY,
    iconUrl: 'agritourism.svg',
    slug: 'agritourism',
  },
  {
    title: 'Pousada',
    id: 'POUSADA_BRAZIL',
    type: HotelSearchQuery_Category.POUSADA_BRAZIL,
    iconUrl: 'pousada.svg',
    slug: 'pousada',
  },
  {
    title: 'Cabin',
    id: 'CABIN',
    type: HotelSearchQuery_Category.CABIN,
    iconUrl: 'cabin.svg',
    slug: 'cabin',
  },
  {
    title: 'Residence',
    id: 'RESIDENCE',
    type: HotelSearchQuery_Category.RESIDENCE,
    iconUrl: 'residence.svg',
    slug: 'residence',
  },
  {
    title: 'Townhouse',
    id: 'TOWN_HOUSE',
    type: HotelSearchQuery_Category.TOWN_HOUSE,
    iconUrl: 'townhouse.svg',
    slug: 'townhouse',
  },
  {
    title: 'Campsite',
    id: 'CAMPSITE',
    type: HotelSearchQuery_Category.CAMPSITE,
    iconUrl: 'campsite.svg',
    slug: 'campsite',
  },
  {
    title: 'Ryokan',
    id: 'RYOKAN',
    type: HotelSearchQuery_Category.RYOKAN,
    iconUrl: 'ryokan.svg',
    slug: 'ryokan',
  },
  {
    title: 'All-Inclusive',
    id: 'ALL_INCLUSIVE_PROPERTY',
    type: HotelSearchQuery_Category.ALL_INCLUSIVE_PROPERTY,
    iconUrl: 'all-inclusive.svg',
    slug: 'all-inclusive',
  },
  {
    title: 'Chalet',
    id: 'CHALET',
    type: HotelSearchQuery_Category.CHALET,
    iconUrl: 'chalet.svg',
    slug: 'chalet',
  },
  {
    title: 'Riad',
    id: 'RIAD',
    type: HotelSearchQuery_Category.RIAD,
    iconUrl: 'riad.svg',
    slug: 'riad',
  },
  {
    title: 'Holiday Park',
    id: 'HOLIDAY_PARK',
    type: HotelSearchQuery_Category.HOLIDAY_PARK,
    iconUrl: 'holiday-park.svg',
    slug: 'holiday-park',
  },
  {
    title: 'Safari',
    id: 'SAFARI_TENTALOW',
    type: HotelSearchQuery_Category.SAFARI_TENTALOW,
    iconUrl: 'safari.svg',
    slug: 'safari',
  },
  {
    title: 'Condo Resort',
    id: 'CONDOMINIUM_RESORT',
    type: HotelSearchQuery_Category.CONDOMINIUM_RESORT,
    iconUrl: 'condo-resort.svg',
    slug: 'condo-resort',
  },
  {
    title: 'Cruise',
    id: 'CRUISE',
    type: HotelSearchQuery_Category.CRUISE,
    iconUrl: 'cruise.svg',
    slug: 'cruise',
  },
  {
    title: 'Houseboat',
    id: 'HOUSEBOAT',
    type: HotelSearchQuery_Category.HOUSEBOAT,
    iconUrl: 'houseboat.svg',
    slug: 'houseboat',
  },
  {
    title: 'Property',
    id: 'PROPERTY',
    type: HotelSearchQuery_Category.PROPERTY,
    iconUrl: 'property.svg',
    slug: 'property',
  },
  {
    title: 'Castle',
    id: 'CASTLE',
    type: HotelSearchQuery_Category.CASTLE,
    iconUrl: 'castle.svg',
    slug: 'castle',
  },
  {
    title: 'Ranch',
    id: 'RANCH',
    type: HotelSearchQuery_Category.RANCH,
    iconUrl: 'ranch.svg',
    slug: 'ranch',
  },
  {
    title: 'Palace',
    id: 'PALACE',
    type: HotelSearchQuery_Category.PALACE,
    iconUrl: 'palace.svg',
    slug: 'palace',
  },
]

// | 'POUSADA_PORTUGAL'
// | 'POUSADA_BRAZIL'

// { title: 'Capsule Hotel', id: 'CA', iconUrl: 'capsule-hotel.svg', slug: 'capsule-hotel' },
// { title: 'Campervan', id: 'MOBILE_HOME', iconUrl: 'camper-van.svg', slug: 'campervan' },
// { title: 'Business', id: '', iconUrl: 'business.svg', slug: 'business' },
// { title: 'Beach', id: '', iconUrl: 'beach.svg', slug: 'beach' },
// { title: 'Family', id: '', iconUrl: 'family.svg', slug: 'family' },
// { title: 'Golf', id: '', iconUrl: 'golf.svg', slug: 'golf' },
// { title: 'Hot Springs', id: '', iconUrl: 'hot-springs.svg', slug: 'hot-springs' },
// { title: 'City', id: '', iconUrl: 'city.svg', slug: 'city' },
// { title: 'Pet Friendly', id: '', iconUrl: 'pet-friendly.svg', slug: 'pet-friendly' },
// { title: 'Casino', id: '', iconUrl: 'casino.svg', slug: 'casino' },
// { title: 'Spa', id: '', iconUrl: 'spa.svg', slug: 'spa' },
// { title: 'Luxury', id: '', iconUrl: 'luxury.svg', slug: 'luxury' },
// { title: 'Sustainable', id: '', iconUrl: 'sustainable.svg', slug: 'sustainable' },
// { title: 'Natural', id: '', iconUrl: 'natural.svg', slug: 'natural' },
// { title: 'Adventure', id: '', iconUrl: 'adventure-sport.svg', slug: 'adventure' },
// { title: 'Winery', id: '', iconUrl: 'winery.svg', slug: 'winery' },
// { title: 'Shopping', id: '', iconUrl: 'shopping.svg', slug: 'shopping' },
// { title: 'Ski', id: '', iconUrl: 'ski.svg', slug: 'ski' },
// { title: 'Romantic', id: '', iconUrl: 'romantic.svg', slug: 'romantic' },
// { title: 'Boutique', id: '', iconUrl: 'boutique.svg', slug: 'boutique' },
// { title: 'Historic', id: '', iconUrl: 'historic.svg', slug: 'historic' },
